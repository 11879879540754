import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { AnneeService } from '../service/AnneeService';
import { BrancheService } from '../service/BrancheService';
import { MatiereService } from '../service/MatiereService';

import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import { NiveauEnseignementService } from '../service/NiveauEnseignementService';
import { FileService } from '../service/FileService';
import { Toast } from 'primereact/toast';
import { PeriodeService } from '../service/PeriodeService';
import { ProgressionService } from '../service/ProgressionService';
import { DetailProgressionService } from '../service/DetailProgressionService';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
const Progression = () => {

  const initObject = { id: null }
  const initProgression = {
    niveau: { id: null },
    annee: { id: null },
    branche: { id: null },
    matiere: { id: null }
  }

  const initDetail = {
    titre: ''
  }
  const [anneesFiltered, setAnneesFiltered] = useState([]);
  const [annees, setAnnees] = useState([]);
  const [annee, setAnnee] = useState(initObject);
  const [openLoaderFlat, setOpenLoaderFlat] = useState(false);
  const [niveaux, setNiveaux] = useState([]);
  const [niveau, setNiveau] = useState(initObject);
  const [branche, setBranche] = useState(initObject);
  const [branches, setBranches] = useState([]);
  const [matiere, setMatiere] = useState(initObject);
  const [matieres, setMatieres] = useState(initObject);
  const [periodes, setPeriodes] = useState([]);
  const [moisList, setMoisList] = useState([]);
  const [progression, setProgression] = useState(initProgression);
  const [selectedProgression, setSelectedProgression] = useState({});
  const [progressions, setProgressions] = useState([]);
  const [datas, setDatas] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const [importedCols, setImportedCols] = useState([{ field: '', header: '', value: '' }]);
  const [detail, setDetail] = useState(initDetail);
  const [progressionSelectedInfo, setProgressionSelectedInfo] = useState({ annee: '', branche: '', matiere: '' });
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [confirmDetailDeleteDialog, setConfirmDetailDeleteDialog] = useState(false);
  const [saveFlag, setSaveFlag] = useState(true);
  const [openEditDialogFlag, setOpenEditDialogFlag] = useState(false)

  const [visibleForm, setVisibleForm] = useState('hidden');
  const [visibleListForm, setVisibleListForm] = useState('');
  const [hiddenLoader, setHiddenLoader] = useState('hidden');

  const dt = useRef(null);
  const toast = useRef(null);
  const fileService = new FileService();

  useEffect(() => {
    const anneeService = new AnneeService();
    const periodeService = new PeriodeService();
    anneeService.getListToCentral().then(res => {
      setAnnees(res);
    })
    periodeService.getList().then(res => {
      setPeriodes(res);
      setMoisList(res.filter(res => res.periodicite.id === 1))
    })
  }, []);

  const setAnneeChange = (val) => {
    // console.log(val);
    setAnnee({ id: val });
    const progressionService = new ProgressionService();
    progressionService.getByAnnee(val).then(res => setProgressions(res)).catch(error => console.log('Erreur get list progression'))
  }

  const onSelectChange = (e, id) => {
    let _progression = { ...progression };
    if (id === 'niveau') {
      const anneeScolaireService = new AnneeService();
      const brancheService = new BrancheService();
      const matiereService = new MatiereService();

      matiereService.getByNiveauEnseignementProjection(e.value).then(res => setMatieres(res))
      anneeScolaireService.getListToCentralByNiveauProjection(e.value).then(res => setAnneesFiltered(res));
      brancheService.getByNiveauEnseigmentProjection(e.value).then(res => setBranches(res))
      let _niveau = { ...niveau };
      _niveau.id = e.value;
      setNiveau(_niveau)
    }
    _progression[id].id = e.value;
    setProgression(_progression);
    saveBtnDisplayer();
  }

  const onSelectFormChange = (e, field) => {
    let _detail = { ...detail };
    if (field === 'periode') {
      _detail[field] = { id: e.value, libelle: periodes.filter((p) => p.id == e.value)[0].libelle };
      // console.log(_detail);
    } else {
      _detail[field] = e.value;
    }
    setDetail(_detail);
  }

  const onInputFormChange = (e, id) => {
    let _detail = { ...detail };

    setDetail(_detail);
    // console.log(_detail)
    if (e.target.value) {
      _detail[id] = e.target.value;
    } else {
      _detail[id] = e.value;
    }
  }

  function isBlank(data) {
    if (data) {
      return false;
    } else {
      return true;
    }
  }

  const saveBtnDisplayer = () => {
    if (!isBlank(progression.annee?.id) && !isBlank(progression.branche?.id) && !isBlank(progression.matiere?.id) && !isBlank(progression.niveau?.id)) {
      setSaveFlag(false)
    } else {
      setSaveFlag(true)
    }
  }

  const onSelectDataChange = (e, i, id) => {
    let _datas = [...datas];
    if (id === 'periode') {
      _datas[i][id] = { id: e.value };
    } else {
      _datas[i][id] = e.value;
    }
    setDatas(_datas);
  }

  const openLoaderDialog = () => {
    const niveauService = new NiveauEnseignementService();
    niveauService.getList().then(res => {
      setNiveaux(res);
    })
    setOpenLoaderFlat(true);
  }

  const anneeLibelle = (rowData) => {
    return rowData.libelle + ' (' + rowData.niveauEnseignement.libelle + ')';
  }

  const loaderFormHeader = () => {
    return (<div className='surface-200 p-2'>
      <h3>Formulaire de chargemement des progressions</h3>
    </div>)
  }

  const dataValidator = () => {
    let flat = true;
    if (datas.length == 0) {
      flat = false;
    }
    flat = detailValidator(datas, flat);
    return flat;
  }

  const detailValidator = (list, flat) => {
    let f = flat;
    // console.log(list)
    list.forEach(obj => {
      if (!obj.periode.id || !obj.mois || !obj.semaine || !obj.numLecon || !obj.titre || !obj.heure) {
        f = false;
      }
    });
    return f;
  }

  async function buildRequestObject() {
    progression.datas = datas;
    console.log('requestObject built.');
  }

  async function save() {
    await buildRequestObject();
    const progressionService = new ProgressionService();
    progressionService.save(progression).then((res) =>{
      setHiddenLoader('hidden');
      setImportedCols([]);
      setDatas([]);
      toast.current.show({ severity: 'success', summary: 'Succès', detail: res, life: 10000 });
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.response?.data, life: 10000 });
      setHiddenLoader('hidden');
    });
  }

  const throwErrorMessage = () => {
    console.log('Validator Exception: fields not correctly set')
    toast.current.show({ severity: 'error', summary: 'Validation', detail: 'Vérifier que tous les champs sont correctement renseignés', life: 10000 });
  }

  const saveProcess = () => {
    setHiddenLoader('');
    if (dataValidator()) {
      save()
    } else {
      throwErrorMessage()
      setHiddenLoader('hidden');
    }
  }

  const findIndexById = (id, list) => {
    let index = -1;
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  const saveDetailProcess = () => {
    const detailProgressionService = new DetailProgressionService();
    if (detailValidator([detail], true)) {
      detailProgressionService.save(detail).then((res) => {
        let _index = findIndexById(res.id, datas);
        let _datas = [...datas];
        let _data = { ...detail };
        if (_index !== -1) {
          _datas[_index] = _data;
        } else {
          _data.id = res.id;
          _datas.push(_data)
        }
        // console.log(_datas);
        setDatas(_datas);
        setVisibleForm('hidden');
        setVisibleListForm('');
        toast.current.show({ severity: 'success', summary: res.title, detail: res.detail, life: 5000 });
      }).catch((error) => {
        toast.current.show({ severity: 'error', summary: error.title, detail: error.detail, life: 5000 });
      });
      setSubmitted(false);
    } else {
      setSubmitted(true);
      throwErrorMessage();
    }
  }

  const hideDetailForm = () => {
    setVisibleForm('hidden');
    setVisibleListForm('');
    setDetail({ titre: '', progressionId: detail.progressionId });
  }

  const footerFormHeader = () => {
    return (<div className='shadow-3'>
      <Button className='my-2' label={'Enregistrer'} disabled={saveFlag} icon={ <i className={hiddenLoader+ " pi pi-spin pi-spinner mr-2"} style={{'fontSize': '1em'}}></i>} onClick={(e) => { saveProcess() }} />
    </div>)
  }

  const toCapitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  const selectOneMenuFields = (rowData, index) => {
    if (index.column.key === '.$0' && datas.length > 0) {
      return (<Dropdown id={"periode_" + index.rowIndex} value={datas[index.rowIndex]?.periode?.id} onChange={(e) => onSelectDataChange(e, index.rowIndex, 'periode')}
        options={periodes} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" />)
    } if (index.column.key === '.$1' && datas.length > 0) {
      return (<Dropdown id={"mois_" + index.rowIndex} value={datas[index.rowIndex]?.mois} onChange={(e) => onSelectDataChange(e, index.rowIndex, 'mois')}
        options={moisList} required optionValue="id" optionLabel="libelle" placeholder="Selectionner le mois" />)
    } else {
      return rowData[index.field]
    }
  }

  const hideDialog = () => {
    setDatas([]);
    setProgression(initProgression);
    setImportedCols([]);
    setImportedData([]);
    setOpenLoaderFlat(false);
  }
  const editProgression = (rowData) => {
    let _buildSelected = { ...progressionSelectedInfo };
    if (rowData) {
      let _detail = { ...detail };
      setDetail(_detail);
      _detail.progressionId = rowData.id;
      _buildSelected.annee = rowData?.annee?.libelle;
      _buildSelected.branche = rowData?.branche?.libelle;
      _buildSelected.matiere = rowData?.matiere?.libelle;
      _buildSelected.niveau = rowData?.niveau?.libelle
    }
    setProgressionSelectedInfo(_buildSelected);
    const detailProgressionService = new DetailProgressionService();
    detailProgressionService.getByProgression(rowData.id).then(res => {
      setDatas(res);
    }).catch((error) => console.log(error.data));
    setOpenEditDialogFlag(true);
    setVisibleListForm('');
  }

  const hideEditProgressionDialog = () => {
    hideDetailForm();
    setDetail(initDetail);
    setVisibleListForm('hidden');
    setProgression(initProgression);
    setDatas([]);
    setOpenEditDialogFlag(false);
  }
  
  const putDetailProgression = (_detail) => {
    // console.log(_detail)
    setVisibleForm('');
    setVisibleListForm('hidden');
    setDetail(_detail);
  }
  
  const confirmDeleteProgression = (rowData) => {
    setConfirmDeleteDialog(true);
    setSelectedProgression(rowData)
  }

  const confirmDeleteDetailProgression = (rowdata) => {
    setConfirmDetailDeleteDialog(true);
    setDetail(rowdata);
  }

  const actionBody = (rowData) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProgression(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProgression(rowData)} />
      </div>
    )
  }

  const actionDetailBody = (rowData) => {

    return (
      <div className="actions">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => putDetailProgression(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteDetailProgression(rowData)} />
      </div>
    )
  }
  const hideDeleteDialog = () => {
    setConfirmDeleteDialog(false);
    setSelectedProgression({});
    console.log('closed');
  }

  const hideDeleteDetailDialog = () => {
    setConfirmDetailDeleteDialog(false);
    setDetail({ titre: '' });
    console.log('closed detail');
  }
  const deleteDetailProgression = (selectedDetails) => {
    const detailProgressionService = new DetailProgressionService();
    detailProgressionService.handleDelete([selectedDetails]).then(res => {
      let _index = findIndexById(detail.id, datas);
      let _datas = [...datas];
      _datas.splice(_index, 1);
      setDatas(_datas);
      toast.current.show({ severity: 'success', summary: 'Succès', detail: res, life: 5000 });
      hideDeleteDetailDialog();
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response?.data, life: 5000 });
    })
  }

  const deleteProgression = (selectedProgression) => {
    const progressionService = new ProgressionService();
    // console.log(selectedProgression);
    progressionService.handleDelete(selectedProgression.id).then(res => {
      let _index = findIndexById(selectedProgression.id, progressions);
      let _progressions = [...progressions];
      _progressions.splice(_index, 1);
      setProgressions(_progressions);
      toast.current.show({ severity: 'success', summary: 'Succès', detail: res, life: 5000 });
      hideDeleteDialog();
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response?.data, life: 5000 });
    })
  }

  const deleteDetailDialogFooter = (
    <>
      <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDetailDialog()} />
      <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={() => deleteDetailProgression(detail.id)} />
    </>
  );

  const deleteDialogFooter = (
    <>
      <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={() => deleteProgression(selectedProgression)} />
    </>
  );

  const moisDetailBody = (rowData) => {
    let _mois = moisList.filter((month) => month.id == rowData.mois)
    if (_mois && _mois.length >= 0) {
      _mois = _mois[0];
    }
    // console.log(_mois);
    return (<InputText disabled={true} value={_mois?.libelle} ></InputText>)
  }

  const importExcel = (e) => {
    const file = e.files[0];

    import('xlsx').then(xlsx => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const wb = xlsx.read(e.target.result, { type: 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

        const cols = data[0];
        data.shift();

        let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
        let _importedData = data.map(d => {

          return cols.reduce((obj, c, i) => {
            obj[c] = d[i];
            return obj;
          }, {});
        });

        let _buildDatas = _importedData.map(elmt => {
          let dataTemp = {};
          dataTemp.periode = elmt['Période'] != null ? { id: elmt['Période'] } : {};
          dataTemp.mois = elmt['Mois'];
          dataTemp.semaine = elmt['Semaine'];
          dataTemp.numLecon = elmt['Numéro leçon'];
          dataTemp.titre = elmt['Titre Leçon'];
          dataTemp.heure = elmt['heure'];
          return dataTemp;
        })

        setImportedCols(_importedCols);
        setImportedData(_importedData);
        setDatas(_buildDatas);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  return (
    <div className='p-1'>
      <div className='p-3 card flex justify-content-end flex-wrap'>
        <Button className='m-1 shadow-4' icon="pi pi-upload" label='Charger progression' onClick={() => openLoaderDialog()} />
      </div>

      <div className='card'>
        <Toast ref={toast} />
        <p className='font-bold text-lg shadow-2 p-3'>REFERENTIEL DES PROGRESSIONS PEDAGOGIQUES </p>
        <div className='my-4 flex justify-content-center border-round flex-wrap surface-900 p-2'>
          <h6 htmlFor='annee' className='font-bold m-2 text-white text-lg'>Année scolaire</h6>
          <Dropdown id='annee' className='ml-1 w-5' value={annee.id} optionValue='id' optionLabel={anneeLibelle} options={annees} placeholder='S&eacute;lectionner l&apos;ann&eacute;e' onChange={(e) => setAnneeChange(e.value)} />
        </div>
        <DataTable className='mt-3' value={progressions} emptyMessage='Aucune donnée trouvée'>
          <Column field="branche.libelle" className='w-3' header='Branche'></Column>
          <Column field='matiere.libelle' header='Matiere'></Column>
          <Column className='w-2' header='Action' body={actionBody}></Column>
        </DataTable>
      </div>

      <Dialog visible={openLoaderFlat} style={{ width: '90%', 'minWidth': '50%', height: '80%', 'minHeight': '50%' }} header={loaderFormHeader} footer={footerFormHeader} onHide={hideDialog}>
        <Toast ref={toast} />
        <div className='grid col-12 shadow-3'>
          <div className='grid col-12'>
            <div className="col-3 sm:col-12 md:col-3">
              <h6 className='mb-1'><b>Niveau d&apos;enseignement</b></h6>
              <Dropdown id="niveau" value={progression.niveau.id} onChange={(e) => onSelectChange(e, 'niveau')} options={niveaux} required optionValue="id" optionLabel="libelle" placeholder="Selectionner le niveau" className={classNames({ 'p-invalid': submitted && !(niveau.id) })} />
              {submitted && !(niveau.id) && <small className="p-invalid">Le niveau est requis.</small>}
            </div>
            <div className="col-3 sm:col-12 md:col-3">
              <h6 className='mb-1'><b>Ann&eacute;e scolaire</b></h6>
              <Dropdown id="annee" value={progression.annee.id} onChange={(e) => onSelectChange(e, 'annee')} options={anneesFiltered} required optionValue="id" optionLabel="libelle" placeholder="Selectionner l&apos;ann&eacute;e" className={classNames({ 'p-invalid': submitted && !(annee.id) })} />
              {submitted && !(annee.id) && <small className="p-invalid">L&apos;ann&eacute;e est requise.</small>}
            </div>
            <div className="col-3 sm:col-12 md:col-3">
              <h6 className='mb-1'><b>Branche</b></h6>
              <Dropdown id="branche" value={progression.branche.id} onChange={(e) => onSelectChange(e, 'branche')} options={branches} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la branche" className={classNames({ 'p-invalid': submitted && !(branche.id) })} />
              {submitted && !(branche.id) && <small className="p-invalid">La branche est requise.</small>}
            </div>
            <div className="col-3 sm:col-12 md:col-3">
              <h6 className='mb-1'><b>Mati&egrave;re</b></h6>
              <Dropdown id="matiere" value={progression.matiere.id} onChange={(e) => onSelectChange(e, 'matiere')} options={matieres} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la matiere" className={classNames({ 'p-invalid': submitted && !(matiere.id) })} />
              {submitted && !(matiere.id) && <small className="p-invalid">La matiere est requise.</small>}
            </div>
          </div>
          <div className='col-12'>
            <FileUpload name='demo' chooseOptions={{ label: 'Excel', icon: 'pi pi-file-excel', className: 'p-button-success m-1' }} mode='basic' chooseLabel='Sélectionner le fichier ...' url={fileService.getUrlFileSend}
              auto onUpload={importExcel} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></FileUpload>
          </div>
        </div>

        <div className='m-2'>
          <DataTable value={importedData} emptyMessage="Aucune progression" paginator rows={10} alwaysShowPaginator={false} responsiveLayout="scroll">
            {
              importedCols.map((col, index) => <Column key={index} body={selectOneMenuFields} field={col.field} header={col.header} />)
            }
          </DataTable>
        </div>
      </Dialog>

      <Dialog visible={openEditDialogFlag} style={{ width: '90%', 'minWidth': '50%', height: '90%', 'minHeight': '50%' }} onHide={hideEditProgressionDialog}>
        <div className='grid'>
          <div className={visibleListForm + " formgroup-inline col-12 shadow-1"}>
            <div className='pl-3 font-bold shadow-2 col-12'>
              <p className='text-lg surface-200 p-2'>DETAILS DE LA PROGRESSION</p>
              <p className='pl-3 m-0'><span className='text-blue-800'>Ann&eacute;e</span> : <span className='font-normal font-italic'>{progressionSelectedInfo.annee}</span></p>
              <p className='pl-3 m-0'><span className='text-blue-800'>Niveau d&apos;enseignement</span> : <span className='font-normal font-italic'>{progressionSelectedInfo.niveau}</span> </p>
              <p className='pl-3 m-0'><span className='text-blue-800'>Branche :</span> <span className='font-normal font-italic'>{progressionSelectedInfo.branche}</span></p>
              <p className='pl-3'><span className='text-blue-800'>Mati&egrave;re :</span> <span className='font-normal font-italic'>{progressionSelectedInfo.matiere}</span></p>
            </div>
            <div className='col-12 flex flex-row-reverse flex-wrap p-2'>
              <Button icon="pi pi-plus" label='Ajouter' className="mr-2" onClick={() => { putDetailProgression(detail) }} />
            </div>
            <DataTable value={datas} className='col-12' emptyMessage="Aucune donnée" paginator rows={20} alwaysShowPaginator={true} responsiveLayout='scroll'>
              <Column field='periode.libelle' header='Période' headerStyle={{ width: '15%', minWidth: '7rem' }}></Column>
              <Column field='mois' body={moisDetailBody} header='Mois' headerStyle={{ width: '15%', minWidth: '7rem' }}></Column>
              <Column field='semaine' header='Semaine' headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
              <Column field='numLecon' header='Numéro' headerStyle={{ width: '7%', minWidth: '7rem' }} ></Column>
              <Column field='titre' header='Titre' ></Column>
              <Column field='heure' header='Nbre. Heure' headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
              <Column field='ordre' header='Ordre' headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
              <Column header='Actions' body={actionDetailBody} headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
            </DataTable>
          </div>

          <div className={visibleForm + " grid formgroup-inline col-12"}>
            <div className='m-2 px-2 py-3 surface-200 font-bold col-12 shadow-2'>
              <p className='text-lg'>{detail.id ? 'MODIFIER' : 'AJOUTER'}  UN DETAIL DE PROGRESSION</p>
            </div>
            <div className='m-2 p-2 col-12 shadow-2'>

              <div className="field col-12">
                <label htmlFor="periode"><b>Période</b></label>
                <Dropdown id="periode" value={detail.periode?.id} onChange={(e) => onSelectFormChange(e, 'periode')} options={periodes} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(detail.periode?.id) })} />
                {submitted && !(detail.periode?.id) && <small className="p-invalid">La période est requise.</small>}
              </div>

              <div className="field col-12">
                <label htmlFor="mois"><b>Mois</b></label>
                <Dropdown id="mois" value={detail.mois} onChange={(e) => onSelectFormChange(e, 'mois')} options={moisList} required optionValue="id" optionLabel="libelle" placeholder="Selectionner le mois" className={classNames({ 'p-invalid': submitted && !(detail.mois) })} />
                {submitted && !(detail.mois) && <small className="p-invalid">Le mois est requis.</small>}
              </div>

              <div className="field col-12">
                <label htmlFor="semaine"><b>Semaine</b></label>
                <InputNumber id="semaine" value={detail.semaine} onValueChange={(e) => onInputFormChange(e, 'semaine')} required optionValue="id" className={classNames({ 'p-invalid': submitted && !(detail.semaine) })} />
                {submitted && !(detail.semaine) && <small className="p-invalid">La semaine est requise.</small>}
              </div>

              <div className="field col-12">
                <label htmlFor="num"><b>Numéro Leçon</b></label>
                <InputNumber id="num" value={detail.numLecon} onValueChange={(e) => onInputFormChange(e, 'numLecon')} required className={classNames({ 'p-invalid': submitted && !(detail.numLecon) })} />
                {submitted && !(detail.numLecon) && <small className="p-invalid">Le numéro est requis.</small>}
              </div>

              <div className="field col-12">
                <label htmlFor="titre"><b>Titre</b></label>
                <InputText id="titre" value={detail.titre} onChange={(e) => onInputFormChange(e, 'titre')} required className={classNames({ 'p-invalid': submitted && !(detail.titre) })} />
                {submitted && !(detail.titre) && <small className="p-invalid">Le titre est requis.</small>}
              </div>

              <div className="field col-12">
                <label htmlFor="heure"><b>Nombre d&apos;heure</b></label>
                <InputNumber id="heure" value={detail.heure} onValueChange={(e) => onInputFormChange(e, 'heure')} required className={classNames({ 'p-invalid': submitted && !(detail.heure) })} />
                {submitted && !(detail.heure) && <small className="p-invalid">L&apos;heure est requise.</small>}
              </div>

              <div className="field col-12">
                <label htmlFor="ordre"><b>Ordre</b></label>
                <InputNumber id="ordre" value={detail.ordre} onValueChange={(e) => onInputFormChange(e, 'ordre')} required className={classNames({ 'p-invalid': submitted && !(detail.ordre) })} />
                {submitted && !(detail.ordre) && <small className="p-invalid">L&apos;ordre est requis.</small>}
              </div>

              <div className="field col-12">
                <Button className='m-2' label={detail.id ? 'Modifier' : 'Enregistrer'} onClick={(e) => { saveDetailProcess() }} />
                <Button className='m-2 p-button-secondary' label={'Annuler'} onClick={(e) => { hideDetailForm() }} />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog visible={confirmDetailDeleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDetailDialogFooter} onHide={hideDeleteDetailDialog}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {detail.id && <span>Etes vous sûr de vouloir supprimer cette ligne?</span>}
        </div>
      </Dialog>

      <Dialog visible={confirmDeleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {selectedProgression.id && <span>Etes vous sûr de vouloir supprimer cette progression?</span>}
        </div>
      </Dialog>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Progression, comparisonFn);
