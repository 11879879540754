import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
// import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from '../../AppTopbar';
// import { AppFooter } from '../../AppFooter';
// import { AppMenu } from '../../AppMenu';
import CompteParent from "./CompteParent";
import { AppConfig } from '../../AppConfig'
import { Toast } from 'primereact/toast';
// import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Tag } from 'primereact/tag';
import { Carousel } from 'primereact/carousel';
import { SchoolService } from '../../service/SchoolService';
// import App2 from "../../App2";
// import App3 from "../../App3.js";
import PrimeReact from 'primereact/api';
// import { Tooltip } from 'primereact/tooltip';
// import { LoginAppTopbar } from "../../LoginAppTopbar";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../../assets/demo/flags/flags.css';
import '../../assets/demo/Demos.scss';
import '../../assets/layout/layout.scss';
import '../../App.scss';
// import 'react-app-polyfill/ie11';
import { useHistory } from "react-router-dom";
// import { Image } from 'primereact/image';
import SouscripEcole from "./SouscripEcole";
// import ImprimerRapports from "../Rapports/ImprimerRapports";
// import Connexion from "./Connexion";
// import Dashboard from "../Dashboard";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useForm } from "react-hook-form";
// import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { ConnexionService } from "../../service/operations/ConnexionService";
import emailjs from "@emailjs/browser";
import { AnneeService } from '../../service/AnneeService';
import { ProgressSpinner } from "primereact/progressspinner";
// import CandidatPersonnelAccueil from "./CandidatPersonnelAccueil";
// import CandidatFondateurAccueil from "./CandidatFondateurAccueil";

// import EducateurAccueil from "./EducateurAccueil";
// import FondateurAccueil from "./FondateurAccueil" ;
// import GroupeGainAcueilAccueil from "./GroupeGainAcueilAccueil";
// import ProfesseurAccueil from "./ProfesseurAccueil";
// import SansProfil from "./SansProfil";
const Accueil = () => {

    let emptyProfil = {
        profilid: null,
        profilcode: '',
        profil_libelle: ''
    };
    let emptyEcole = {
        ecoleid: null,
        ecolecode: '',
        ecoleclibelle: ''
    };
    let connexioDtoEmpty = {
        ecoleid: null,
        profilid: null,
        email: '',
        motdePasse: '',
        login: ''
    };
    // let sessionVarEmpty = {
    //     annee_id: null,
    //     ecole_id: null,
    //     connect: '',
    //     profil: '',
    //     nomprenom: '',
    //     personnelid: null
    // };

    let infoPersonnEmpty = {
        personnelid: null,
        personnelnom: '',
        personnelprenom: ''
    };

    // let infoCandidatEmpty = {
    //     candidatid: null,
    //     candidat_nom: '',
    //     candidat_prenom: '',
    //     candidat_email: '',
    //     connect: '',
    //     libelleFonction:''
    // };

    // const [con, setConn] = useState(sessionStorage.getItem('connect'));
    const toastBC = useRef(null);
    const toast = useRef(null);
    const [callCompte, setcallCompte] = useState(null);
    const [ecole, setEcole] = useState(emptyEcole);
    // const [sessionVar, setSessionVar] = useState(sessionVarEmpty);
    const [infoPers, setInfoPers] = useState(infoPersonnEmpty);
    // const [infosCandidat, setInfosCandidat] = useState(infoCandidatEmpty);
    const [ecoles, setEcoles] = useState([]);
    const [profil, setProfil] = useState(emptyProfil);
    const [profils, setProfils] = useState([]);
    const [idUser, setIdUser] = useState(null);
    const [connexionDto, setConnexionDto] = useState(connexioDtoEmpty);
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [productDialog, setProductDialog] = useState(false);
    const [parentDialog, setParentDialog] = useState(false);
    const [retourConnexion, setRetourConnexion] = useState('');
    const [retourConnexion1, setRetourConnexion1] = useState('');
    const [retourConnexionAdm, setRetourConnexionAdm] = useState('');

    const [professDialog, setProfessDialog] = useState(false);
    const [forgetDialog, setForgetDialog] = useState(false);
    const [adminDialog, setAdminDialog] = useState(false);
    const [resetDialog, setResetDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitted1, setSubmitted1] = useState(false);
    const [submittedAdmin, setSubmittedAdmin] = useState(false);
    const [submittedReset, setSubmittedReset] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');

    const copyTooltipRef = useRef();
    const location = useLocation();
    const [userName, setUserName] = useState('Pouls-scolaire');
    const [userEmail, setUserEmail] = useState('sousmith1@gmail.com');
    const [userMessage, setUserMessage] = useState('SOUMMMMM');
    const [passWord, setPassWord] = useState('');
    const [monpassWord, setMonPassWord] = useState('');
    const [email, setEmail] = useState('');
    const [emailAdmin, setEmailAdmin] = useState('');
    const [emailReset, setEmailReset] = useState('');
    const [passWordAdmin, setPassWordAdmin] = useState('');
    const [monEmail, setMonEmail] = useState('');
    const [forgetEmail, setforgetEmail] = useState('');
    // const [codeEcole, setCodeEcole] = useState('01');
    const form = useRef();
    let history = useHistory();
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;
    let sendEmailEmpty = {
        subject: 'Bonjour SOUM',
        user_name: 'SOUMSMITH1',
        user_email: 'soumsmith1@gmail.com',
        message: 'Juste un text'
    };

    const defaultValues = {
        name: '',
        email: '',
        password: '',
        date: null,
        country: null,
        accept: false,
        text: ''
    }

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }
    const hideDialog = () => {
        setSubmitted1(false);
        setProductDialog(false);
    }
    const hideParentDialog = () => {
        // setSubmitted1(false);
        setParentDialog(false);
    }
    const hideAdminDialog = () => {
        setSubmittedAdmin(false);
        setAdminDialog(false);
    }
    const hideResetDialog = () => {
        setSubmittedReset(false);
        setResetDialog(false);
    }

    const hideProffDialog = () => {
        setSubmitted(false);
        setProfessDialog(false);
    }
    const hideforgetDialog = () => {
        setForgetDialog(false);
    }
    const showConfirm = () => {
        toastBC.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="flex flex-column" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>{retourConnexion}</h4>

                    </div>

                </div>
            )
        });
    }
    const showConfirm1 = () => {
        toastBC.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="flex flex-column" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>{retourConnexion1}</h4>

                    </div>

                </div>
            )
        });
    }
    const showConfirmAdmin = () => {
        toastBC.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="flex flex-column" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>{retourConnexionAdm}</h4>

                    </div>

                </div>
            )
        });
    }

    const recuperMotDepasse = () => {
        let destinataire = emailReset;
        let message;
        let login;
        let motDepasse;

        let objet = "Vos paramètres d'identifications"

        const connexServ = new ConnexionService();
        connexServ.infosParam(emailReset)
            .then(data => {

                if (data.message === 'utilisateur trouve') {

                    motDepasse = data.parametre.motDepass
                    login = data.parametre.login
                    message = "Login :" + login + "  Mot de passe:" + motDepasse;
                    connexServ.sendEmail(destinataire, message, objet)
                        .then(data => {
                            toast.current.show({ severity: 'success', summary: 'Vie-ecoles: Succes', detail: 'mail envoyé!', life: 3000 });

                        });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Vie-ecoles: Messages d"erreur', detail: 'Cette adresse email n"existe pas', life: 3000 });

                }
                //  console.log(data)
                sessionStorage.setItem('isLoggedIn', true)
                setInfoPers(data)

            });




    }

    //    save infos admin
    const saveAdmin = () => {
        setSubmittedAdmin(true);
        setRetourConnexionAdm('');
        if (emailAdmin.trim() && passWordAdmin.trim()) {
            connexionDto.email = emailAdmin;
            connexionDto.motdePasse = passWordAdmin;
            connexionDto.login = emailAdmin;
            const connexServ = new ConnexionService();
            connexServ.seConnecterAdmin(connexionDto)
                .then(data => {
                    console.log("data Admin " + data)
                    sessionStorage.setItem('profil', data)
                    setRetourConnexionAdm(data)
                });
            sessionStorage.setItem('connect', '2')

            //get IDUser
            connexServ.idUserConnecte(emailAdmin)
                .then(data => {
                    console.log(data)
                    setIdUser(data)
                    sessionStorage.setItem('idUser', data)
                });

        } else {
            toast.current.show({ severity: 'error', summary: 'pouls-scolaire: Messages d"erreur', detail: 'Veuillez renseigner les différents champs svp', life: 3000 });

        }
    }
    //   save info candidat
    const saveProduct1 = () => {
        const connexServ = new ConnexionService();
        console.log('Submit');
        setSubmitted1(true);
        setRetourConnexion1('');
        if (email.trim() && passWord.trim()) {
            connexServ.connexionCandidat(email, passWord)
                .then(data => {
                    console.log(data)
                    setRetourConnexion1(data)
                });
            connexServ.infoPersonConnecteCandidat(email)
                .then(data => {
                    //  console.log(data)
                    sessionStorage.setItem('isLoggedIn', true)
                    setInfoPers(data)
                    sessionStorage.setItem('connect', data.connect)
                    sessionStorage.setItem('CandidatId', data.candidatid)
                    sessionStorage.setItem('infosCandidat', JSON.stringify(data));
                    sessionStorage.setItem('NomPrenom', data.candidat_nom + ' ' + data.candidat_prenom);
                    sessionStorage.setItem('emailCandidat', email);
                    sessionStorage.setItem('profil', 'CANDIDAT')
                    sessionStorage.setItem('refresh', 1)
                    sessionStorage.setItem('fonction', data.libelleFonction)


                });
            //get IDUser
            connexServ.idUserConnecte(email)
                .then(data => {
                    console.log(data)
                    setIdUser(data)
                    sessionStorage.setItem('idUser', data)
                });



        } else {
            toast.current.show({ severity: 'error', summary: 'pouls-scolaire: Messages d"erreur', detail: 'Veuillez renseigner les différents champs svp', life: 3000 });

        }
    }

    const saveParent = () => {
        const connexServ = new ConnexionService();



        if (ecole) {
            sessionStorage.setItem('EcoleID', ecole.ecoleid)
            connexServ.getMainyearByEcole(ecole.ecoleid)
                .then(data => {
                    sessionStorage.setItem('AnneEncours', data.id)
                    sessionStorage.setItem('niveauEnseignementId', data.niveauEnseignement.id)
                    sessionStorage.setItem('periodiciteId', data.periodicite.id)
                    sessionStorage.setItem('CompteParent', '1');
                    history.push("/home/compteParent");
                });

        } else {
            toast.current.show({ severity: 'error', summary: 'pouls-scolaire: Messages d"erreur', detail: 'Veuillez renseigner l"ecole  svp', life: 3000 });

        }
    }



    useEffect(() => {
        // professionnel
        if (profil.profil_libelle.trim() && retourConnexion.trim()) {
            if (retourConnexion === profil.profil_libelle) {
                sessionStorage.setItem('connectcandidat', 'ok');
                setProfessDialog(false);
                history.push("/home/professionnelle");
            } else {
                sessionStorage.setItem('connect', null)
                sessionStorage.setItem('CandidatId', null)
                sessionStorage.setItem('infosCandidat', null)
                sessionStorage.setItem('NomPrenom', null)
                sessionStorage.setItem('profil', null)
                sessionStorage.setItem('EcoleID', null)
                sessionStorage.setItem('AnneEncours', null)
                sessionStorage.setItem('isLoggedIn', null)
                sessionStorage.setItem('niveauEnseignementId', null)
                sessionStorage.setItem('periodiciteId', null)
                showConfirm();
            }
        }
    }, [retourConnexion]);

    useEffect(() => {
        // Admin
        if (emailAdmin.trim() && retourConnexionAdm.trim()) {
            if (retourConnexionAdm.trim() === 'Admin') {
                sessionStorage.setItem('connectcandidat', 'ok');
                setAdminDialog(false);
                history.push("/home/professionnelle");
                setRetourConnexionAdm('');
            } else {
                sessionStorage.setItem('connect', null)
                sessionStorage.setItem('CandidatId', null)
                sessionStorage.setItem('infosCandidat', null)
                sessionStorage.setItem('NomPrenom', null)
                sessionStorage.setItem('profil', null)
                sessionStorage.setItem('EcoleID', null)
                sessionStorage.setItem('AnneEncours', null)
                sessionStorage.setItem('niveauEnseignementId', null)
                sessionStorage.setItem('periodiciteId', null)
                sessionStorage.setItem('isLoggedIn', null)
                showConfirmAdmin();
                //  return ;
            }
        }
    }, [retourConnexionAdm]);

    useEffect(() => {
        // Candidat
        let isMounted = true
        if (isMounted) {
            if (email.trim() && retourConnexion1.trim()) {
                if (retourConnexion1.trim() === 'Mot de passe correct!') {
                    sessionStorage.setItem('connectcandidat', 'ok');
                    setProductDialog(false);
                    history.push("/home/candidat/index");
                    setRetourConnexion1('');
                } else {
                    sessionStorage.setItem('connect', null)
                    sessionStorage.setItem('CandidatId', null)
                    sessionStorage.setItem('infosCandidat', null)
                    sessionStorage.setItem('NomPrenom', null)
                    sessionStorage.setItem('profil', null)
                    sessionStorage.setItem('EcoleID', null)
                    sessionStorage.setItem('AnneEncours', null)
                    sessionStorage.setItem('niveauEnseignementId', null)
                    sessionStorage.setItem('periodiciteId', null)
                    sessionStorage.setItem('isLoggedIn', null)

                    showConfirm1();
                    //  return ;
                }
            }
        }

        return () => { isMounted = false }
    }, [retourConnexion1]);

    const showForgetForm = () => {
        setForgetDialog(true);
    }
    const sendEmailForm = () => {
        setForgetDialog(true);
    }
    const sendEmail = (e) => {
        //  e.preventDefault();
        emailjs.sendForm('service_94gv03t', 'template_khmrq5i', form.current, 'rQ8B8i3jHyGwChdlj')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    const emailService = (e) => {
        //  e.preventDefault();
        if (forgetEmail.trim()) {
            setUserName('POULS-SCOLAIRE');
            setUserEmail(forgetEmail.trim());
            setUserMessage('Bonjour Monsieur Soumahoro Moustapha' +
                'Veuillez utiliser ces paramètres pour vous connecter à notre application' + '\n');
            sendEmail();
        }
    };


    //   save info professionnel
    const connectProcess = () => {
        //console.log('SOUMAHORO');
        // console.log('Submit*****');
        (async () => {
            setSubmitted(true);
            setRetourConnexion('');
            if (monEmail.trim() && monpassWord.trim() && ecole.ecoleclibelle.trim() && profil.profil_libelle) {
                setIsNotSpinning('')
                connexionDto.email = monEmail;
                connexionDto.ecoleid = ecole.ecoleid;
                connexionDto.profilid = profil.profilid;
                connexionDto.motdePasse = monpassWord;
                connexionDto.login = monEmail;
                const connexServ = new ConnexionService();
                connexServ.seConnecter(connexionDto)
                    .then(data => {
                        console.log(data)
                        setRetourConnexion(data)
                    });
                await connexServ.infoPersonConnecteV2(monEmail, ecole.ecoleid, profil.profilid)
                    .then(data => {
                        console.log('data*** ' + data)
                        sessionStorage.setItem('connect', '2')
                        sessionStorage.setItem('CandidatId', data.personnelid)
                        sessionStorage.setItem('infosCandidat', JSON.stringify(data))
                        sessionStorage.setItem('NomPrenom', data.personnelnom + ' ' + data.personnelprenom)
                        sessionStorage.setItem('profil', data.profil)
                        console.log('profil connexion ::: ' + data.profil)
                        sessionStorage.setItem('EcoleID', ecole.ecoleid)
                        sessionStorage.setItem('EcoleLibelle', ecole.ecoleclibelle)
                        setInfoPers(data)
                    });
                //get IDUser
                await connexServ.idUserConnecte(monEmail)
                    .then(data => {
                        console.log('idUser' + data)
                        setIdUser(data)
                        sessionStorage.setItem('idUser', data)
                    });

                await connexServ.getMainyearByEcole(ecole.ecoleid)
                    .then(data => {
                        sessionStorage.setItem('AnneEncours', data.id)
                        sessionStorage.setItem('niveauEnseignementId', data.niveauEnseignement?.id)
                        sessionStorage.setItem('periodiciteId', data.periodicite?.id)
                    });

                const anneeService = new AnneeService();
                await anneeService.getInfosAnnee(ecole.ecoleid).then(res => {
                    sessionStorage.setItem('topBarInfos', JSON.stringify(res));
                    console.log(res);
                }
                )
                window.location.reload(true);

            } else {
                toast.current.show({ severity: 'error', summary: 'pouls-scolaire: Messages d"erreur', detail: 'Veuillez renseigner les différents champs svp', life: 3000 });

            }
            setIsNotSpinning('hidden')
        })()
    }

    const productDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Se connecter" icon="pi pi-check" className="p-button-text" onClick={saveProduct1} />
        </>
    )
    const parentDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideParentDialog} />
            <Button label="Se connecter" icon="pi pi-check" className="p-button-text" onClick={saveParent} />
        </>
    )
    const productAdminDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideAdminDialog} />
            <Button label="Se connecter" icon="pi pi-check" className="p-button-text" onClick={saveAdmin} />
        </>
    )

    const productResetDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideResetDialog} />
            <Button label="Envoyer le mail" icon="pi pi-check" className="p-button-text" onClick={recuperMotDepasse} />
        </>
    )


    const professDialogFooter = (
        <>
            {/*<Button label="Mot de passe oublié?" icon="pi pi-check" className="p-button-text" onClick={showForgetForm} />*/}
            <div className="field">
                <ProgressSpinner className={isNotSpinning} style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>

            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideProffDialog} />

            <Button label="Se connecter" icon="pi pi-check" className="p-button-text" onClick={connectProcess} />

        </>
    )

    const forgetDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideforgetDialog} />
            <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={emailService} />
        </>
    )
    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onSubmit = (data) => {
        //  onClick() ;
        if (email) {

            sessionStorage.setItem('connect', '1');
            history.push('/home/candidat/index')
        }

    };
    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }
    useEffect(() => {

        setcallCompte(0)
    }, []);
    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }


    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    const items3 = [

        {
            label: "Offres d'emploi",
            icon: 'pi pi-caret-right',
            command: () => {
                history.push("/home/candidat/Postuler")
            }
        }
    ];
    const items2 = [
        {
            label: 'Etablissements',
            icon: 'pi pi-caret-right',
            command: () => {
                localStorage.setItem('conn', 'ok');
                setcallCompte(1);
                history.push("/home/professionnelle/inscrireEtablissement")

            }
        },
        {
            label: 'Professionnels',
            icon: 'pi pi-caret-right',
            command: () => {
                history.push("/home/professionnelle/creerSonCompte")
            }
        }
    ];

    const items = [
        {
            label: 'Personnel',
            icon: 'pi pi-caret-right',
            command: () => {
                setProfessDialog(true);
            }
        },
        {
            label: "Candidat",
            icon: 'pi pi-caret-right',
            command: () => {
                setProductDialog(true);

            }
        },
        {
            label: 'Groupe Gain',
            icon: 'pi pi-caret-right',
            command: () => {
                setAdminDialog(true);

            }
        },


        {
            label: 'Parents',
            icon: 'pi pi-caret-right',
            command: () => {
                window.location.href = 'https://pouls-scolaire.com/'
            }
        },
        {
            label: 'Elève',
            icon: 'pi pi-caret-right',
            command: () => {
                window.location.href = 'https://pouls-scolaire.com/'
            }
        },
        {
            label: 'Recuperer mot de passe',
            icon: 'pi pi-caret-right',
            command: () => {
                setResetDialog(true);
            }
        }
    ];
    const [schools, setSchools] = useState([]);

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const onProfilChange = (e) => {
        setProfil(e.value);
    };
    const callCompteParent = () => {
        setParentDialog(true)
    };

    const onEcoleChange = (e) => {
        setEcole(e.value);
    };
    const schoolTemplate = (school) => {
        return (
            <div className="product-item">
                <div>
                    <div className="p-mb-3 text-center">
                        <div>
                            <img src={`images/schools/${school.image}`} width="100px" onError={(e) => e.target.src = 'images/schools/ecole.png'} alt={school.name} className="product-image" />
                        </div>
                        <div className='font-bold text-lg'>{school.libelle}</div>
                    </div>
                </div>
            </div>
        );
    }

    const dt = useRef(null);


    useEffect(() => {

        const schoolService = new SchoolService();
        schoolService.getSchools().then(data => setSchools(data));
        const connexSer = new ConnexionService();
        connexSer.listProfil().then(data => setProfils(data));
        connexSer.listEcoleConnecte().then(data => setEcoles(data));

    }, []);


    return (
        <div>
            <Toast ref={toastBC} position="center" />
            {
                (() => {
                    if (callCompte === 1) {
                        return (
                            <div >

                                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />


                                <div className="layout-main-container">
                                    <div className="layout-main">

                                        <Route path="/home/professionnelle/inscrireEtablissement" component={SouscripEcole} />
                                    </div>

                                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                                </div>

                                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />
                            </div>

                        )

                    } else {
                        return (
                            <div>

                                <div className="grid crud-demo">

                                    <div className='col-12'>
                                        <div className='card m-0'>
                                            <Toast ref={toast}></Toast>
                                            <div className="grid grid-nogutter surface-section text-800">
                                                <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                                                    <section>
                                                        <span className="block text-6xl font-bold mb-1">Gerer votre établissement avec un outil adapté à vos attentes</span>
                                                        <div className="text-6xl text-primary font-bold mb-3">Pouls-scolaire est la solution pour une éducation encadrée et maitrisée</div>
                                                        <p className="mt-0 mb-4 text-700 line-height-3">Logiciel tout en un pour piloter la gestion quotidienne de votre établissement scolaire. Meilleure solution pour école maternelle, primaire, collège, lycée, enseignement supérieur privée ou publique.</p>

                                                        <SplitButton label="S'incrire" model={items2} buttonClassName="p-mr-2 p-button-raised" menuButtonClassName="p-button-outlined"></SplitButton>

                                                        <SplitButton label="Se connecter" model={items} className="p-mr-2" buttonClassName="p-button-outlined" menuButtonClassName="p-button-outlined"></SplitButton>

                                                        <SplitButton label="Emploi" model={items3} className="p-mr-2" buttonClassName="p-button-outlined" menuButtonClassName="p-button-outlined"></SplitButton>
                                                    </section>
                                                </div>
                                                <div className="col-12 md:col-6 overflow-hidden" style={{ height: '700px' }}>
                                                    <img src="images/blocks/accueil/bg_eleve_primaire.jpeg" alt="hero-1" className="md:ml-auto block md:h-full" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
                                                </div>
                                                <div
                                                    className="col-12 md:col-6 shadow-2 flex flex-column md:flex-row md:align-items-center justify-content-between mb-3"
                                                    style={{
                                                        borderRadius: '1rem',
                                                        background: 'linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)'
                                                    }}
                                                >
                                                    <div class="grid" >
                                                        <div className="col">
                                                            <img alt="logo" src="images/parent_responsable.svg" className="h-7rem">
                                                            </img>
                                                        </div>

                                                        <div className="col">
                                                            <Button label="COMPTE PARENT>>Cliquez ici" severity="secondary" text raised onClick={() => callCompteParent()} />
                                                        </div>


                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='card border-1 p-2 flex align-content-start'>
                                            <Tag value="Info" icon="pi pi-info-circle" severity="danger" className='px-3 fadein animation-duration-1000  animation-iteration-infinite text-sm'></Tag>
                                            <div className={`marquee-rtl` + ' mx-5 pt-1 w-screen text-lg font-medium p-mb-3  white-space-nowrap overflow-hidden text-overflow-clip'}><div>Bientôt la cérémonie officielle de présentation du service Pouls-Scolaire</div> </div>
                                        </div>
                                    </div>

                                    <div className='col-9'>
                                        <div className='card p-1'>
                                            <div className='font-italic text-center border-round font-bold text-lg p-2 bg-black-alpha-90 text-white'>ACTUALITES</div>
                                            <div className='h-14rem grid p-3'>
                                                <div className='col-3 border-round border-1'>

                                                </div>
                                                <div className='col-3 border-round border-1'>

                                                </div>
                                                <div className='col-3 border-round border-1'>

                                                </div>
                                                <div className='col-3 border-round border-1'>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-3'>
                                        <div className='card p-1'>
                                            <div className='font-italic text-center border-round font-bold text-lg p-2 bg-black-alpha-90 text-white'> PUBLICITE</div>
                                            <div className='h-14rem text-center white-space-nowrap overflow-hidden text-overflow-clip'>
                                                <img className={`tourne`} src="images/gain-logo.jpg" width="50%" height="auto" alt="Logo groupe GAIN" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'></div>

                                    <div className='col-12'>
                                        <div className='card grid'>
                                            <div className='col-offset-2 col-8 grid'>
                                                <div className='col-4'>
                                                    <div className='text-center'>
                                                        <div><i className="pi pi-users text-indigo-600" style={{ 'fontSize': '3em' }}></i></div>
                                                        <div className='font-normal font-semibold mt-1'>Gestion des comptes d'utilisateur </div>
                                                        <div className='mt-3 text-sm font-italic'>Accès simple et sécurisé</div>
                                                    </div>
                                                </div>

                                                <div className='col-4'>
                                                    <div className='text-center'>
                                                        <div><i className="pi pi-building text-indigo-600" style={{ 'fontSize': '3em' }}></i></div>
                                                        <div className='font-normal font-semibold mt-1'>Gestion des Salles de classes</div>
                                                        <div className='mt-3 text-sm font-italic'>Connaitre la disponibilité des salles de classe à tout moment de votre Plannification</div>
                                                    </div>
                                                </div>

                                                <div className='col-4'>
                                                    <div className='text-center'>
                                                        <div><i className="pi pi-chart-pie text-indigo-600" style={{ 'fontSize': '3em' }}></i></div>
                                                        <div className='font-normal font-semibold mt-1'>Gestion des Moyennes</div>
                                                        <div className='mt-3 text-sm font-italic'>Prise en compte flexible et automatisée des notes des élèves</div>
                                                    </div>
                                                </div>

                                                <div className='col-3 mt-7'>
                                                    <div className='text-center'>
                                                        <div><i className="pi pi-eye text-indigo-600" style={{ 'fontSize': '3em' }}></i></div>
                                                        <div className='font-normal font-semibold mt-1'>Suivi des élèves</div>
                                                        <div className='mt-3 text-sm font-italic'>disponibilité des informations sur les élèves et sur tout son parcours scolaire</div>
                                                    </div>
                                                </div>
                                                <div className='col-3 mt-7'>
                                                    <div className='text-center'>
                                                        <div><i className="pi pi-bell text-indigo-600" style={{ 'fontSize': '3em' }}></i></div>
                                                        <div className='font-normal font-semibold mt-1'>Notification en temps réel</div>
                                                        <div className='mt-3 text-sm font-italic'>Restez informé de toutes les activités des élèves en tant que parents</div>
                                                    </div>
                                                </div>
                                                <div className='col-3 mt-7'>
                                                    <div className='text-center'>
                                                        <div><i className="pi pi-calendar text-indigo-600" style={{ 'fontSize': '3em' }}></i></div>
                                                        <div className='font-normal font-semibold mt-1'>Plannification des cours</div>
                                                        <div className='mt-3 text-sm font-italic'>Définissez aisement votre emploi du temps</div>
                                                    </div>
                                                </div>
                                                <div className='col-3 mt-7'>
                                                    <div className='text-center'>
                                                        <div><i className="pi pi-briefcase text-indigo-600" style={{ 'fontSize': '3em' }}></i></div>
                                                        <div className='font-normal font-semibold mt-1'>Recrutement du personnel</div>
                                                        <div className='mt-3 text-sm font-italic'>Recrutez plus facilement votre personnel à travers une large communauté de professionnels</div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-offset-2 col-8 card'>
                                        <div className='font-italic text-center text-xl font-normal font-bold m-2'><span className='text-2xl'>I</span>ls nous font confiance</div>
                                        <div className=' h-8rem m-5'>
                                            <Carousel value={schools} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                                                autoplayInterval={3000} itemTemplate={schoolTemplate} />
                                        </div>
                                    </div>
                                    {/* Dialog candidat */}
                                    <Dialog visible={productDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                                        <div className="flex flex-column align-items-center justify-content-center">
                                            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                                                    <div className="text-center mb-5">
                                                        <div className="text-900 text-3xl font-medium mb-3">Mon espace Candidat! </div>
                                                        <span className="text-600 font-medium">Connectez vous pour continuer</span>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                                            Login
                                                        </label>
                                                        <InputText inputid="email1" type="text" value={email == null ? '' : email} onChange={(e) => setEmail(e.target.value)} placeholder="Login" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />
                                                        <label htmlFor="passWord" className="block text-900 text-xl font-medium mb-2">
                                                            Mot de passe
                                                        </label>
                                                        <Password inputid="passWord" feedback={false} value={passWord == null ? '' : passWord} onChange={(e) => setPassWord(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem'></Password>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </Dialog>

                                    {/* Compte Parent */}
                                    <Dialog visible={parentDialog} style={{ width: '450px' }} header="" modal className="p-fluid" footer={parentDialogFooter} onHide={hideParentDialog}>
                                        <div className="flex flex-column align-items-center justify-content-center">
                                            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                                                    <div className="text-center mb-5">
                                                        <div className="text-900 text-3xl font-medium mb-3">Compte Parent! </div>
                                                        <span className="text-600 font-medium">Parent Responsable</span>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="codeEcole" className="block text-900 text-xl font-medium mb-2">
                                                            Ecole
                                                        </label>
                                                        <Dropdown id="codeEcole" options={ecoles} value={ecole == null ? '' : ecole} onChange={onEcoleChange} optionLabel="ecoleclibelle" filter showClear filterBy="ecoleclibelle"
                                                        ></Dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </Dialog>

                                    {/* Dialog Admin */}
                                    <Dialog visible={adminDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={productAdminDialogFooter} onHide={hideAdminDialog}>
                                        <div className="flex flex-column align-items-center justify-content-center">
                                            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                                                    <div className="text-center mb-5">
                                                        <div className="text-900 text-3xl font-medium mb-3">Administrateur Gain </div>
                                                        <span className="text-600 font-medium">Connectez vous pour continuer</span>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                                            Login
                                                        </label>
                                                        <InputText inputid="email1" type="text" value={emailAdmin == null ? '' : emailAdmin} onChange={(e) => setEmailAdmin(e.target.value)} placeholder="Login" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />
                                                        <label htmlFor="passWord" className="block text-900 text-xl font-medium mb-2">
                                                            Mot de passe
                                                        </label>
                                                        <Password inputid="passWord" feedback={false} value={passWordAdmin == null ? '' : passWordAdmin} onChange={(e) => setPassWordAdmin(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem'></Password>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>

                                    <Dialog visible={resetDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={productResetDialogFooter} onHide={hideResetDialog}>
                                        <div className="flex flex-column align-items-center justify-content-center">
                                            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                                                    <div className="text-center mb-5">
                                                        <div className="text-900 text-3xl font-medium mb-3">Utilisateur </div>
                                                        <span className="text-600 font-medium">Recuper son mot de passe</span>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                                            Email
                                                        </label>
                                                        <InputText inputid="email1" type="text" value={emailReset == null ? '' : emailReset} onChange={(e) => setEmailReset(e.target.value)} placeholder="Adresse email" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>

                                    <Dialog visible={forgetDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={forgetDialogFooter} onHide={hideforgetDialog}>
                                        <form ref={form} onSubmit={emailService}>
                                            <div className="flex flex-column align-items-center justify-content-center">
                                                <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>

                                                        <div>
                                                            <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                                                Login
                                                            </label>
                                                            <InputText inputid="email1" type="text" value={forgetEmail == null ? '' : forgetEmail} onChange={(e) => setforgetEmail(e.target.value)} placeholder="Adresse email" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />

                                                        </div>
                                                        <input type="text" name="user_name" value={userName} onChange={(e) => setUserName(e.target.value)} hidden={true} />

                                                        <input type="email" name="user_email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} hidden={true} />

                                                        <textarea name="message" value={userMessage} onChange={(e) => setUserMessage(e.target.value)} hidden={true} />
                                                        <input type="submit" value="Send" hidden={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </Dialog>


                                    <Dialog visible={professDialog} style={{ width: '650px' }} header="" modal className="p-fluid" footer={professDialogFooter} onHide={hideProffDialog}>
                                        <div className="flex flex-column align-items-center justify-content-center">
                                            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>

                                                    <div>
                                                        <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                                            Login
                                                        </label>
                                                        <InputText inputid="email1" type="text" value={monEmail == null ? '' : monEmail} onChange={(e) => setMonEmail(e.target.value)} placeholder="Login" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />

                                                        <label htmlFor="passWord" className="block text-900 text-xl font-medium mb-2">
                                                            Mot de passe
                                                        </label>
                                                        <Password inputid="passWord" value={monpassWord == null ? '' : monpassWord} onChange={(e) => setMonPassWord(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem' feedback={false}></Password>

                                                        <label htmlFor="codeEcole" className="block text-900 text-xl font-medium mb-2">
                                                            Ecole
                                                        </label>
                                                        <Dropdown id="codeEcole" options={ecoles} value={ecole == null ? '' : ecole} onChange={onEcoleChange} optionLabel="ecoleclibelle" filter showClear filterBy="ecoleclibelle"
                                                        ></Dropdown>

                                                        <label htmlFor="profil" className="block text-900 text-xl font-medium mb-2">
                                                            Profil
                                                        </label>
                                                        <Dropdown id="profil" options={profils} value={profil == null ? '' : profil} onChange={onProfilChange} optionLabel="profil_libelle" filter showClear filterBy="profil_libelle"
                                                        ></Dropdown>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </Dialog>





                                </div>



                            </div>


                        )
                    }
                })()
            }
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(PageAccueil, comparisonFn);
export default Accueil;
