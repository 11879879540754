import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import { Rating } from "primereact/rating";
import React, { useEffect, useState, useRef } from "react";
import { ClasseEleveService } from "../../service/ClasseEleveService";
import { EvaluationService } from "../../service/EvaluationService";
import { PersonnelMatiereClasseService } from "../../service/PersonnelMatiereClasseService";
import { useHistory } from "react-router-dom";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../css/TabView.css';
import { SeanceService } from "../../service/SeanceService";
import { Dialog } from "primereact/dialog";
import { AppelNumeriqueService } from "../../service/AppelNumeriqueService";
import { ToggleButton } from 'primereact/togglebutton';
import { Message } from 'primereact/message';
import { Toast } from "primereact/toast";

const DashBoardProfesseur = () => {

    const emptyEleveDto = { eleves: [] };
    const personnelId = sessionStorage.getItem('CandidatId');
    const [classes, setClasses] = useState([]);
    const [classesMatieres, setClasseMatieres] = useState([]);
    const [classesMatieresFinal, setClasseMatieresFinal] = useState([]);
    const [classe, setClasse] = useState(null);
    const [distinctClasses, setDistinctClasses] = useState([]);
    const [pieOptions, setPieOptions] = useState(null);
    const [hidden, setHidden] = useState("hidden");
    const [hiddenSpinner, setHiddenSpinner] = useState("");
    const [effectif, setEffectif] = useState(0);
    const [seancesProf, setSeancesProf] = useState([]);
    const [openAppelFlag, setOpenAppelFlag] = useState(false);
    const [elevesDto, setElevesDto] = useState(emptyEleveDto);
    const [heureSaving, setHeureSaving] = useState('');
    const [iterationCountStyle, setIterationCountStyle] = useState({ '': '' });
    const [animateClass, setAnimateClass] = useState('');
    const [presentCmpt, setPresentCmpt] = useState(0);
    const [absentCmpt, setAbsentCmpt] = useState(0);
    const [responseMessage, setResponseMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [hideMessage, setHideMessage] = useState('hidden');
    const [appelIsAlreadySaveClass, setAppelIsAlreadySaveClass] = useState('');
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    let history = useHistory();

    const toast = useRef(null);
    const pieData = {
        labels: ['heures enseignées', 'heures restantes', 'Absences'],
        datasets: [
            {
                data: [1, 48, 1],
                backgroundColor: [
                    "#32CD32",
                    "#36A2EB",
                    "#FF6384"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }
        ]
    };

    const applyLightTheme = () => {
        const pieOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            }
        };
        setPieOptions(pieOptions)
    }
    useEffect(() => {
        history.push("/home/professionnelle");
    }, [history]);

    function formatDate(date) {
        const date1 = (date == null ? '' : date.split('T'));
        if (date1) {
            date = new Date(date1[0]);
            let heure = date1[1].split('Z');
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear()
            ].join('/') + ' à ' + heure[0];
        } else
            return 'N/A';
    }
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    const displayInfos = (e) => {
        let index = e.target.value;
        let indexTab = index.split(',');
        let evaluationService = new EvaluationService();
        setHidden('hidden');
        setClasseMatieres([]);
        for (let i = 0; i < indexTab.length; i++) {
            if (i == 0) {
                setClasse(classes[[indexTab[i]]].classe);
                let classeEleveService = new ClasseEleveService();
                classeEleveService.getEffectifByClasseAndAnnee(classes[indexTab[i]].classe.id, sessionStorage.getItem('AnneEncours')).then(
                    res => setEffectif(res)
                )
            }
            evaluationService.getCountByClasseAndMatiere(sessionStorage.getItem("AnneEncours"), classes[indexTab[i]].classe.id, classes[indexTab[i]].matiere.id).then(res => {
                let _classe = { ...classes[indexTab[i]] };
                _classe.nbreEval = res;
                classesMatieres.push(_classe);
                console.log(classesMatieres.length)
                //Pour afficher l'ensemble du detail des matieres enseignées on ouvre la zone d'affichage à la fin des traitements
                if (i == indexTab.length - 1) {
                    setHidden("");
                    setClasseMatieresFinal(classesMatieres);
                }

            });
        }


    }

    const detailDisplay = (row, index) => {
        return (
            <div className="col-12 grid" key={index}>
                <div className="col-12 text-lg font-bold">
                    <span className="font-bold text-lg p-success p-tag">Matière enseignée : </span> {row.matiere ? row.matiere.libelle : "N/A"}
                </div>
                <div className="col-4 text-lg border-top-1 border-400 pl-8">
                    <div className="font-semibold my-2">
                        <span className="text-base">Nombre d'heures total:</span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base">Heures Enseignées:</span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base ">Evaluation(s) Effectuée(s):</span> <Badge value={row.nbreEval !== '' ? '' + row.nbreEval + '' : "N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base ">Nombre de séances:</span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base ">Séances effectuées: </span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base ">Absences: </span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base inline">Notation: </span> <Rating className="inline ml-2" value={0} readOnly={true} />
                    </div>

                </div>
                <div className="col-8 border-top-1 border-400">

                    <Chart type="pie" data={pieData} options={pieOptions} style={{ width: '20%' }} />

                </div>
            </div>
        )
    }
    const horaireBody = (rowData) => {
        return (
            <div className={`m-0 py-3 pl-2 font-semibold ${rowData.isClassEnded}`}>
                {rowData.heureDeb} - {rowData.heureFin}
            </div>
        )
    }

    const onHideListeAppel = () => {
        setOpenAppelFlag(false);
        setElevesDto(emptyEleveDto);
        setAbsentCmpt(0);
        setPresentCmpt(0);
        setHeureSaving('');
        setAnimateClass('');
        setIterationCountStyle({ '': '' });
        setHideMessage('hidden');
        // Mettre à jour la liste des emplois du temps pour raffraichir le bouton d'appel
        const seanceService = new SeanceService();
        seanceService.getListByDateAndProf(new Date().toISOString().split('T')[0], sessionStorage.getItem('CandidatId')).then((resp) => {
            setSeancesProf(resp);
        })
        console.log('on hide');
    }

    const openListeAppel = (e) => {
        console.log('open liste appel');
        const appelService = new AppelNumeriqueService();
        appelService.getListAppel(e.target.id, e.target?.attributes['position']?.value).then(res => {
            let now = new Date();
            res.heureDebutAppel = now.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
            res.personnelId = personnelId;
            res.duree = e.target?.attributes['duree']?.value
            setElevesDto(res);
            setPresentCmpt(res.presenceCmpt);
            setAbsentCmpt(res.absenceCmpt);
            setHeureSaving(formatDate(res.dateAppel));
            console.log(res);
        }).catch((error) => {
            console.log(error);
        })

        setOpenAppelFlag(true);
    }

    const appelBody = (rowData) => {
        let classLibelle = 'p-button-outlined p-button-secondary';
        if (rowData.appelAlreadyExist)
            classLibelle = 'p-button-success';
        return (
            <Button id={rowData.id} disabled={!rowData.isVerrou} position={rowData.position} duree={rowData.duree} className={`${classLibelle} ml-2`} label="Appel" severity="success" onClick={(e) => openListeAppel(e)} />
        )
    }

    const saveAppel = () => {
        const appelService = new AppelNumeriqueService();
        console.log(elevesDto);
        if (elevesDto.personnelId != null) {
            appelService.save(elevesDto).then(resp => {
                const dateObj = new Date(Date.parse(resp.headers['date-save']));
                setHeureSaving(dateObj.toLocaleDateString('fr-FR', options));
                setIterationCountStyle({ animationIterationCount: '9', 'color': 'green' });
                setAnimateClass('fadeout animation-duration-500 animation-iteration-9');
                setHideMessage('');
                setResponseMessage(resp.data);
                setSeverity('success');
            }).catch((error) => {
                console.log(error);
                setAnimateClass('fadeout animation-duration-500 animation-iteration-9');
                setIterationCountStyle({ animationIterationCount: '9', 'color': 'red' });
                setHideMessage('');
                setSeverity('error');
                setResponseMessage(error.response.data);
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.response.data, life: 15000 });
            })
        }
    }

    const appelFooter = () => {
        return <div className="p-3 surface-200">
            <Button className="p-button-success" label="Enregistrer" onClick={saveAppel}></Button>
            <Message style={iterationCountStyle} className={'ml-3 ' + animateClass + ' ' + hideMessage} severity={severity} text={responseMessage} />
        </div>
    }

    const onCheckToogleBtn = (e) => {
        let _elevesDto = { ...elevesDto };
        for (const eleve of _elevesDto.eleves) {
            if (eleve.inscriptionClasseEleveId == e.target.id) {
                if (e.target.value === false) {
                    eleve.presence = 'A';
                    setAbsentCmpt(absentCmpt + 1);
                    setPresentCmpt(presentCmpt - 1);
                } else {
                    eleve.presence = 'P';
                    setAbsentCmpt(absentCmpt - 1);
                    setPresentCmpt(presentCmpt + 1);
                }
                break;
            }
        }
        setElevesDto(_elevesDto);
        setAnimateClass('');
        setIterationCountStyle({ '': '' });
        setHideMessage('hidden');
    }

    const appelHeader = () => {
        return (
            <div>
                <div className="field">
                    <span>Séance ({elevesDto.typeSeance}) de {elevesDto.heureDebutSeance} à {elevesDto.heureFinSeance}</span>
                </div>
                <div className="field">
                    <span>Matiere : {elevesDto.matiereLibelle}</span>
                </div>
                <div className="field">
                    <span>Effectif : {elevesDto.effectif}</span> &#x5B;<span style={{ color: 'green' }}> Présent(s) : {presentCmpt}</span> - <span style={{ color: 'red' }}>Absent(s) : {absentCmpt} </span>&#x5D;
                </div>
                <div className="field">
                    <span>Enseignant : {elevesDto.enseignantNomPrenom}</span>
                </div>
                <div>
                    <small><i>Dernier appel enregistré le :</i><span style={iterationCountStyle} className={animateClass}> {heureSaving}</span></small>
                </div>
            </div>
        )
    }

    const fullDateFormat = (date) => {
        const jours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const mois = [
            "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
        if (date == null)
            date = new Date();
        // console.log(jours[date.getDay()]);
        // console.log(date.getDate());
        // console.log(mois[date.getMonth()]);
        // console.log(date.getFullYear());
        return jours[date.getDay()] + ' ' + date.getDate() + ' ' + mois[date.getMonth()] + ' ' + date.getFullYear();
    }

    const nomPrenomsEleve = (rowData) => {
        return rowData.nom + ' ' + rowData.prenoms;
    }

    const presence = (rowData) => {
        return <ToggleButton onLabel="P" offLabel="A" id={rowData.inscriptionClasseEleveId} checked={(rowData.presence === 'A' ? false : true)} onChange={(e) => onCheckToogleBtn(e)} />
    }

    useEffect(() => {
        // liste des classes enseignées par le professeur
        const personnelClasseMatiereService = new PersonnelMatiereClasseService();
        const seanceService = new SeanceService();
        personnelClasseMatiereService.getByProfesseur(sessionStorage.getItem('AnneEncours'), personnelId, sessionStorage.getItem('EcoleID')).then(res => {
            setClasses(res);
            // console.log(res);
            let tabCtrl = [];
            for (let i = 0; i < res.length; i++) {
                let flag = false;
                let indexFlag = -1;
                for (let j = 0; j < tabCtrl.length; j++) {
                    if (res[i].classe.id === tabCtrl[j].id) {
                        flag = true;
                        indexFlag = j;
                    }
                }

                // console.log("--- data before");
                // console.log(tabCtrl);
                // console.log(res[i].classe);
                if (!flag) {
                    // console.log("-> tabnonincludes");
                    let _classe = { ...res[i].classe };
                    tabCtrl.push(res[i].classe);
                    _classe.indexes = i;
                    distinctClasses.push(_classe);
                } else {
                    // console.log("-> tabincludes");
                    let _classe = { ...distinctClasses[indexFlag] };
                    _classe.indexes = _classe.indexes + ',' + i;
                    distinctClasses[indexFlag] = { ..._classe };

                }

            }
            setHiddenSpinner('hidden');
            // console.log(distinctClasses);
            // console.log("--- data fin");
        });

        seanceService.getListByDateAndProf(new Date().toISOString().split('T')[0], sessionStorage.getItem('CandidatId')).then((resp) => {
            console.log(resp);
            setSeancesProf(resp);
        })
        // Appliquer le theme light au graphique
        applyLightTheme();
    }, [distinctClasses])

    const messageHeaderTab = () => {
        return "dd"

    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card surface-800">
                    <p className="font-bold text-2xl text-white"> Dashboard du professeur</p>
                    <p className="ml-3 font-bold text-lg font-italic text-white"> Bienvenue</p>
                </div>
            </div>

            <div className="card col-12">

                <TabView className="tabview-pouls">
                    <TabPanel className="tabview-header-icon" header="Mes activités" leftIcon="pi pi-calendar">
                        <h6><i><b className="text-green-700">{fullDateFormat()}</b></i></h6>
                        <h4><b>Mon emploi du temps du jour</b></h4>
                        <DataTable value={seancesProf} size="small" responsiveLayout="scroll" emptyMessage="Aucune séance trouvée">
                            <Column body={horaireBody} className="p-0" header="Horaire"></Column>
                            <Column header="Type" className="p-0" body={(rowData) => <div className={`m-0 py-3 ${rowData.isClassEnded}`}>{rowData.typeActivite.libelle}</div>} ></Column>
                            <Column header="Matière" className="p-0" body={(rowData) => <div className={`m-0 py-3 ${rowData.isClassEnded}`}>{rowData.matiere.libelle}</div>} ></Column>
                            <Column header="Classe" className="p-0" body={(rowData) => <div className={`m-0 py-3 ${rowData.isClassEnded}`}>{rowData.classe.libelle}</div>} ></Column>
                            <Column header="Salle" className="p-0" body={(rowData) => <div className={`m-0 py-3 ${rowData.isClassEnded}`}>{rowData.salle.libelle}</div>} ></Column>
                            <Column header="Actions" body={appelBody}></Column>
                        </DataTable>
                    </TabPanel>
                    <TabPanel className="tabview-header-icon grid" header="Mes classes" leftIcon="pi pi-home">

                        <div className="p-3 col-12">
                            <ProgressSpinner visible={false} style={{ width: '30px', height: '30px', visibility: hiddenSpinner }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            {distinctClasses.length !== 0 ? distinctClasses.map((elmt, index) => <Button key={index} style={{ visibility: (hiddenSpinner === 'hidden' ? '' : 'hidden') }} value={elmt.indexes} label={elmt.libelle} className="p-button-outlined mr-2 mb-2" onClick={displayInfos} />) : <i className="text-orange-800">Vous n'avez aucune classe à enseigner</i>}
                        </div>

                        <div className={"col-12 card " + hidden}>
                            <div className="grid">

                                <div className="col-3 font-bold">
                                    <span className="text-lg p-tag">Classe : </span> {classe ? classe.libelle : "N/A"}
                                </div>

                                <div className="col-3 font-bold">
                                    <span className="text-lg p-tag">Effectif : </span> {effectif}
                                </div>
                                {classesMatieresFinal.map((elmt, index) => detailDisplay(elmt, index))}

                            </div>
                        </div>

                    </TabPanel>
                    <TabPanel header="Mes messages" disabled={true} className="tabview-header-icon" rightIcon="pi pi-envelope">
                        <h3>Non disponible.</h3>
                    </TabPanel>
                    <TabPanel header="Mes Statistiques" disabled={true} className="tabview-header-icon" leftIcon="pi pi-chart-line">
                        <h3>Non disponible.</h3>
                    </TabPanel>
                </TabView>
            </div>
            <Dialog visible={openAppelFlag} style={{ width: '600px' }} onHide={onHideListeAppel}>
                <Toast ref={toast} />
                <div>
                    <DataTable value={elevesDto.eleves} size="small" header={appelHeader} footer={appelFooter} responsiveLayout="scroll" emptyMessage="Aucun élève trouvé">
                        <Column field={nomPrenomsEleve} header='Nom & Prénoms'></Column>
                        <Column field={presence} header='Présence'></Column>
                    </DataTable>
                </div>
            </Dialog>

        </div>
    );
}


/* const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DashBoardProfesseur, comparisonFn); */
export default DashBoardProfesseur;
