const constants = {

   //host: 'http://localhost:8889/api/',
host: 'https://pouls-scolaire.com/api/',

};

export class BaseService {

    getHost() {
        return constants["host"];
    }
}
