import { Badge } from "primereact/badge";
import { Chart } from "primereact/chart";
import { Knob } from "primereact/knob";
import CountUp from 'react-countup';
import React, { useEffect, useState } from "react";
import { DashBoardFondateurService } from "../../service/DashBoardFondateurService";
import {useHistory} from "react-router-dom";


const DashBoardFondateur = (props) => {
    const [dataBlock, setDataBlock] = useState({});
    const [barOptions, setBarOptions] = useState(null);
    const [pannee, setPannee] = useState(sessionStorage.getItem('AnneEncours'));
    const [pecole, setPecole] = useState(sessionStorage.getItem('EcoleID'));
    const profilHeader = props.profil;

    let yourDate = new Date();
    let history = useHistory();

    useEffect(() => {
        history.push("/home/professionnelle");
        console.log("/home/professionnelle refresh1")
    }, [history]);



    // console.log(props.infos);
    useEffect(() => {

        const dash = new DashBoardFondateurService();
        dash.getDatas(sessionStorage.getItem('EcoleID'), sessionStorage.getItem('AnneEncours')).then(resp => {
            setDataBlock(resp);
          //  console.log(resp)
        });
        const fetchData = async () => {
            try {
                console.log('init annee : ' + pannee + ' ecole : ' + pecole);
                setPannee();
                setPecole();

                const dash = new DashBoardFondateurService();
                const resp = await dash.getDatas(pecole, pannee);

                console.log('setted annee : ' + pannee + ' ecole : ' + pecole);
                setDataBlock(resp);
                console.log(resp);
            } catch (error) {
                console.error('Une erreur s\'est produite :', error);
            }
        };

        fetchData();

        applyLightTheme();

    }, [props.colorMode]);

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        const [date1, time1] = date.split('T');
        if (date1) {

            date = new Date(date1);
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear()
            ].join('/') + ' à ' + time1.split('.')[0];
        } else
            return '';
    }

    const barDataMoyenne = {
        labels: ['Moyenne Générale'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [65]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [28]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [35]
            }
        ]
    };
    const barDataEvaluation = {
        labels: ['Evaluation des élèves'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [8]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [5]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [10]
            }
        ]
    };
    const barDataSeance = {
        labels: ['Séance de cours'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [2]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [1]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [5]
            }
        ]
    };
    const barDataProgression = {
        labels: ['Progression de cours'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [65]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [28]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [35]
            }
        ]
    };
    const barDataAppel = {
        labels: ['Fréquence appel numérique'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [65]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [28]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [35]
            }
        ]
    };
    const barDataPresence = {
        labels: ['Presence des élèves'],
        datasets: [
            {
                label: 'A1',
                backgroundColor: '#008000',
                data: [65]
            },
            {
                label: 'A2',
                backgroundColor: '#FF1493',
                data: [28]
            },
            {
                label: 'A3',
                backgroundColor: '#FF8C00',
                data: [35]
            }
        ]
    };

    const applyLightTheme = () => {
        const barOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };
        setBarOptions(barOptions);
    }

    const pieChartDataStatut = {
        labels: ['NAFF', 'AFF'],
        datasets: [
            {
                data: [dataBlock.nombreEleveNonAff, dataBlock.nombreEleveAff],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };
    const pieChartDataGenre = {
        labels: ['NAFF', 'AFF'],
        datasets: [
            {
                data: [dataBlock.nombreEleveNouvNonAffFilles + dataBlock.nombreEleveNouvNonAffGarcons, dataBlock.nombreEleveNouvAffFilles + dataBlock.nombreEleveNouvAffGarcons],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };
    const pieChartDataAnciennete = {
        labels: ['NAFF', 'AFF'],
        datasets: [
            {
                data: [dataBlock.nombreEleveAncNonAffFilles + dataBlock.nombreEleveAncNonAffGarcons, dataBlock.nombreEleveAncAffFilles + dataBlock.nombreEleveAncAffGarcons],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };
    const pieChartDataDisponible = {
        labels: ['SNOP', 'SOP'],
        datasets: [
            {
                data: [dataBlock.sallesNonOperationnelles, dataBlock.sallesOperationnelles],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };
    const pieChartDataUtilise = {
        labels: ['SNU ', 'SU '],
        datasets: [
            {
                data: [dataBlock.sallesNonUtilisees, dataBlock.sallesUtilisees],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };

    const pieChartDataPersEns = {
        labels: ['Femmes', 'Hommes'],
        datasets: [
            {
                data: [dataBlock.personnelEnseignantFeminin, dataBlock.personnelEnseignantMasculin],
                backgroundColor: [
                    "#FF8C00",
                    "#1E90FF"
                ],
                hoverBackgroundColor: [
                    "#FFA500",
                    "#87CEFA"
                ]
            }
        ]
    };
    const pieChartDataEduc = {
        labels: ['Femmes', 'Hommes'],
        datasets: [
            {
                data: [dataBlock.personnelEducateurFeminin, dataBlock.personnelEducateurMasculin],
                backgroundColor: [
                    "#FF8C00",
                    "#1E90FF"
                ],
                hoverBackgroundColor: [
                    "#FFA500",
                    "#87CEFA"
                ]
            }
        ]
    };
    const pieChartDataAdm = {
        labels: ['Femmes', 'Hommes'],
        datasets: [
            {
                data: [dataBlock.personnelAdmFeminin, dataBlock.personnelAdmMasculin],
                backgroundColor: [
                    "#FF8C00",
                    "#1E90FF"
                ],
                hoverBackgroundColor: [
                    "#FFA500",
                    "#87CEFA"
                ]
            }
        ]
    };

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card bg-green-600">
                    <p className="font-bold text-2xl text-white"> Dashboard {profilHeader}</p>
                    <p className="ml-3 font-bold text-lg font-italic text-white"> Bienvenue</p>
                </div>
            </div>
            <div className="col-12">
                <div className="card grid">
                    <div className="col-3 text-center">
                        <p className="text-lg font-bold">Ce {formatDate(yourDate.toISOString())}</p>
                        <div className="p-field p-col-12 p-md-4">

                            <Knob value={0} readOnly />
                        </div>
                        <p className="font-italic font-bold text-500">La note de votre établissement n'est pas encore disponible</p>
                    </div>
                    <div className="col-9 card-container flex align-items-center surface-100">
                        <div className="mx-2 hidden">
                            <Chart type="bar" data={barDataSeance} options={barOptions} height={'200px'} width={'150px'} />
                        </div>
                        <div className="mx-2 hidden">
                            <Chart type="bar" data={barDataProgression} options={barOptions} height={'200px'} width={'150px'} />
                        </div>
                        <div className="mx-2 hidden">
                            <Chart type="bar" data={barDataAppel} options={barOptions} height={'200px'} width={'150px'} />
                        </div>
                        <div className="mx-2 hidden">
                            <Chart type="bar" data={barDataPresence} options={barOptions} height={'200px'} width={'150px'} />
                        </div>
                        <div className="mx-2 hidden">
                            <Chart type="bar" data={barDataEvaluation} options={barOptions} height={'200px'} width={'150px'} />
                        </div>
                        <div className="mx-2 hidden">
                            <Chart type="bar" data={barDataMoyenne} options={barOptions} height={'200px'} width={'150px'} />
                        </div>
                    </div>
                    <div className="col-12">
                        <p className="font-bold font-italic surface-300">APPRECIATION non disponible</p>
                    </div>
                    <div className="col-12 grid border-400 border-bottom-1">

                        <div className="grid md:col-6 lg:col-6 sm:col-12 font-bold">
                            <div className="col-12 border-400">
                                <p className="font-bold">Votre effectif ce jour {formatDate(yourDate.toISOString())}</p>
                            </div>
                            <div className="col-4 surface-300">
                                <p><CountUp className="p-badge p-badge-lg" style={{backgroundColor:'#000'}} preserveValue={true} start={0} end={dataBlock.nombreTotalEleve} delay={1} /> <span className="mx-3 text-xl">Elèves</span></p>                        </div>
                            <div className="col-4 relative surface-300 ">
                                <p className="text-primary">G : <Badge className="absolute right-0 mr-2 " value={dataBlock.nombreTotalEleveGarcons == 0 ? '0 ' : dataBlock.nombreTotalEleveGarcons} /> </p>
                            </div>
                            <div className="col-4 relative surface-300">
                                <p style={{color:'#FF647F'}}> <span >F</span> : <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2 " value={dataBlock.nombreTotalEleveFilles == 0 ? '0 ' : dataBlock.nombreTotalEleveFilles} /> </p>
                            </div>
                            <div className="col-4 relative border-400 border-bottom-1">
                                <p>AFF: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAff == 0 ? '0 ' : dataBlock.nombreEleveAff} /></p>
                                <p>NAFF: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNonAff == 0 ? '0 ' : dataBlock.nombreEleveNonAff} /></p>
                            </div>
                            <div className="col-4 relative border-400 border-bottom-1">
                                <p className="text-primary">G: <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAffGarcons} /></p>
                                <p className="text-primary">G: <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNonAffGarcons} /></p>
                            </div>
                            <div className="col-4 relative border-400 border-bottom-1">
                                <p style={{color:'#FF647F'}}>F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAffFilles} /></p>
                                <p style={{color:'#FF647F'}}>F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNonAffFilles} /></p>
                            </div>
                            <div className="grid col-12 ml-0 px-0">
                                <div className="col-4 pt-5 relative">
                                    <p className="">NOUVEAU: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouv == 0 ? '0 ' : dataBlock.nombreEleveNouv} /></p>
                                    <p className="pt-5">ANCIEN: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAnc == 0 ? '0 ' : dataBlock.nombreEleveAnc} /></p>
                                </div>

                                <div className="col-4 relative">
                                    <p className="text-primary">AFF <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouvAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNouvAffGarcons} /></p>
                                    <p className="border-bottom-1 text-primary border-400">NAFF <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouvNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveNouvNonAffGarcons} /></p>
                                    <p className="text-primary">AFF <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveAncAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAncAffGarcons} /></p>
                                    <p className="text-primary">NAFF <Badge className="absolute right-0 mr-2" value={dataBlock.nombreEleveAncNonAffGarcons == 0 ? '0 ' : dataBlock.nombreEleveAncNonAffGarcons} /></p>
                                </div>
                                <div className="col-4 relative pl-2">
                                    <p style={{color:'#FF647F'}}>AFF <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouvAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNouvAffFilles} /></p>
                                    <p style={{color:'#FF647F'}} className="border-bottom-1 border-400">NAFF <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveNouvNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveNouvNonAffFilles} /></p>
                                    <p style={{color:'#FF647F'}}>AFF <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAncAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAncAffFilles} /></p>
                                    <p style={{color:'#FF647F'}}>NAFF <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.nombreEleveAncNonAffFilles == 0 ? '0 ' : dataBlock.nombreEleveAncNonAffFilles} /></p>
                                </div>
                            </div>
                        </div>
                        <div className="md:col-6 lg:col-6 sm:col-6">
                            <div className="pt-6 card-container flex align-items-center">
                                <div className="md:col-4 lg:col-4 sm:col-4">
                                    <Chart type="pie" data={pieChartDataStatut} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                                </div>
                                <div className="md:col-6 lg:col-4 sm:col-4">
                                    <Chart type="pie" data={pieChartDataGenre} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                                </div>
                                <div className="md:col-6 lg:col-4 sm:col-4">
                                    <Chart type="pie" data={pieChartDataAnciennete} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 grid border-bottom-1 border-400">

                        <div className="grid md:col-6 lg:col-6 sm:col-12 pl-0 font-bold">
                            <div className="col-12 surface-300">
                                <p>VOTRE EFFECTIF MOYEN PAR CLASSE: <Badge className="text-base mx-1" value={dataBlock.effectifMoyenClasse == 0 ? '0 ' : dataBlock.effectifMoyenClasse} /></p>
                            </div>
                            <div className="col-6 relative"><p>Nombre de classes: <Badge className="absolute right-0 mr-2" value={dataBlock.nombreClasses == 0 ? '0 ' : dataBlock.nombreClasses} /></p></div>
                            <div className="col-6 relative"><p>Salles disponibles : <Badge className="absolute right-0 mr-2" value={dataBlock.sallesDispo == 0 ? '0 ' : dataBlock.sallesDispo} /></p></div>

                            <div className="col-6 relative"><p>Salles opérationnelles: <Badge className="absolute right-0 mr-2" value={dataBlock.sallesOperationnelles == 0 ? '0 ' : dataBlock.sallesOperationnelles} /></p></div>
                            <div className="col-6 relative"><p>Salles non opérationnelles : <Badge className="absolute right-0 mr-2" value={dataBlock.sallesNonOperationnelles == 0 ? '0 ' : dataBlock.sallesNonOperationnelles} /></p></div>

                            <div className="col-6 relative"><p>Salles utilisées: <Badge className="absolute right-0 mr-2" value={dataBlock.sallesUtilisees == 0 ? '0 ' : dataBlock.sallesUtilisees} /></p></div>
                            <div className="col-6 relative"><p>Salles non utilisées : <Badge className="absolute right-0 mr-2" value={dataBlock.sallesNonUtilisees == 0 ? '0 ' : dataBlock.sallesNonUtilisees} /></p></div>
                        </div>


                        <div className="md:col-6 lg:col-6 sm:col-12">
                            <div className="card-container flex align-items-center">
                                <Chart type="pie" data={pieChartDataDisponible} options={lightOptions} style={{ position: 'relative', width: '170px' }} />

                                <Chart type="pie" data={pieChartDataUtilise} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                            </div>

                        </div>
                    </div>
                    <div className="grid md:col-6 lg:col-6 sm:col-12 font-bold">
                        <div className="col-12">
                            Le Personnel de votre établissement : <Badge style={{backgroundColor:'#000'}} value={dataBlock.nombrePersonnel == 0 ? '0 ' : dataBlock.nombrePersonnel} />
                        </div>
                        <div className="col-5 relative">Personnel administratif: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.personnelAdm == 0 ? '0 ' : dataBlock.personnelAdm} /></div>
                        <div className="col-3 relative" style={{color:'#FF647F'}}>
                            F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.personnelAdmFeminin == 0 ? '0 ' : dataBlock.personnelAdmFeminin} />
                        </div>
                        <div className="col-4 relative text-primary">
                            G: <Badge className="absolute right-0 mr-2" value={dataBlock.personnelAdmMasculin == 0 ? '0 ' : dataBlock.personnelAdmMasculin} />
                        </div>

                        <div className="col-5 relative">Personnel Educateur: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.personnelEducateur == 0 ? '0 ' : dataBlock.personnelEducateur} /></div>
                        <div className="col-3 relative" style={{color:'#FF647F'}}>
                            F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.personnelEducateurFeminin == 0 ? '0 ' : dataBlock.personnelEducateurFeminin} />
                        </div>
                        <div className="col-4 relative text-primary">
                            G: <Badge className="absolute right-0 mr-2" value={dataBlock.personnelEducateurMasculin == 0 ? '0 ' : dataBlock.personnelEducateurMasculin} />
                        </div>

                        <div className="col-5 relative">Personnel enseignant: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.personnelEnseignant == 0 ? '0 ' : dataBlock.personnelEnseignant} /></div>
                        <div className="col-3 relative" style={{color:'#FF647F'}}>
                            F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.personnelEnseignantFeminin == 0 ? '0 ' : dataBlock.personnelEnseignantFeminin} />
                        </div>
                        <div className="col-4 relative text-primary">
                            G: <Badge className="absolute right-0 mr-2" value={dataBlock.personnelEnseignantMasculin == 0 ? '0 ' : dataBlock.personnelEnseignantMasculin} />
                        </div>

                        <div className="col-5 relative">Enseignant Permanent: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.enseignantPermanent == 0 ? '0 ' : dataBlock.enseignantPermanent} /></div>
                        <div className="col-3 relative" style={{color:'#FF647F'}}>
                            F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.enseignantPermanentFeminin == 0 ? '0 ' : dataBlock.enseignantPermanentFeminin} />
                        </div>
                        <div className="col-4 relative text-primary">
                            G: <Badge className="absolute right-0 mr-2" value={dataBlock.enseignantPermanentMasculin == 0 ? '0 ' : dataBlock.enseignantPermanentMasculin} />
                        </div>

                        <div className="col-5 relative">Enseignant vacataire: <Badge style={{backgroundColor:'#000'}} className="absolute right-0 mr-2" value={dataBlock.enseignantVacataire == 0 ? '0 ' : dataBlock.enseignantVacataire} /></div>
                        <div className="col-3 relative" style={{color:'#FF647F'}}>
                            F: <Badge style={{backgroundColor:'#FF647F'}} className="absolute right-0 mr-2" value={dataBlock.enseignantVacataireFeminin == 0 ? '0 ' : dataBlock.enseignantVacataireFeminin} />
                        </div>
                        <div className="col-4 relative text-primary">
                            G: <Badge className="absolute right-0 mr-2" value={dataBlock.enseignantVacataireMasculin == 0 ? '0 ' : dataBlock.enseignantVacataireMasculin} />
                        </div>
                    </div>

                    <div className="md:col-6 lg:col-6 sm:col-12 ">
                        <div className="card-container flex align-items-center">
                            <Chart type="pie" data={pieChartDataAdm} options={lightOptions} style={{ position: 'relative', width: '170px' }} />

                            <Chart type="pie" data={pieChartDataEduc} options={lightOptions} style={{ position: 'relative', width: '170px' }} />

                            <Chart type="pie" data={pieChartDataPersEns} options={lightOptions} style={{ position: 'relative', width: '170px' }} />
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-12 card text-2xl font-bold text-center text-red-100">VOUS UTILISEZ <span className="text-pink-800">POULS-SCOLAIRE</span>, Votre indicateur de PERFORMANCE</div>
        </div>
    );
}

export default DashBoardFondateur;


