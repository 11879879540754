import axios from 'axios';
import { BaseService } from './BaseService';


export class FileService extends BaseService {

    async send(){
        
        return axios.post(this.getHost()+'file/send').then(res => res.data);
    }

    get getUrlFileSend(){
        return this.getHost()+'file/send';
    }
}