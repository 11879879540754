import React  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export const LoginAppTopbar = (props) => {

    return (
        <div className="layout-topbar">
           <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-gain.png' : 'assets/layout/images/logo-gain.png'} alt="logo"/>
                <span>Pouls-scolaire</span>
            </Link>


        </div>
    );
}
