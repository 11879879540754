
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Tag } from 'primereact/tag';

import { AnneeService } from './service/AnneeService';
import {useHistory} from "react-router-dom";
import { Link } from 'react-router-dom';

export const AppTopbar = (props) => {
    let history = useHistory();

    // let sessionVarEmpty = {
    //     annee_id: null,
    //     ecole_id: null,
    //     connect: '',
    //     profil: '',
    //     nomprenom: '',
    //     personnelid: null
    // };
    // let candidatObject = null;

    let emptyInfo = {
        'anneeId': '',
        'anneeLibelle': '',
        'periodeId': 0,
        'periodeLibelle': '',
        'dateDebut': '',
        'dateFin': '',
        'dateLimite': '',
        'severity': ''
    }
    const toastBC = useRef(null);
    const [libelle, setLibelle] = useState('');
    // const [sessionVar, setSessionVar] = useState('');
    const [infosAnnee, setInfosAnnee] = useState(emptyInfo);
    // const [obj, setObj]= useState(sessionStorage.getItem('objTest'));
    // const [objx, setObjx]= useState({id:''});
    const toast = useRef(null);
    const logOut = () => {
        // sessionStorage.setItem('connect', '');
        //sessionStorage.setItem('CandidatId', '');
        sessionStorage.clear();
        history.push("/");
    }

    const refresh = () => {
        history.push("/home/professionnelle");
        console("/home/professionnelle")
    }

    const showConfirm = () => {
        toastBC.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="flex flex-column" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>voulez-vous vraiment vous déconnecter</h4>
                        <p>Veuillez s'il vous plaît confirmer</p>
                    </div>
                    <div className="grid p-fluid">
                        <div className="col-6">
                            <Button type="button" onClick={logOut} label="Oui" className="p-button-success" />
                        </div>
                        {/*<div className="col-6">*/}
                        {/*    <Button type="button" onClick={clear}  label="Non" className="p-button-secondary" />*/}
                        {/*</div>*/}
                    </div>
                </div>
            )
        });
    }
    const formatInfosAnnee = (infos) => {
        let lib = '';
        if (infos) {
            lib = infos?.anneeLibelle;
            if (infos.periodeLibelle)
                lib = lib + ' [ ' + infos?.periodeLibelle + ' ]'
        }
        setLibelle(lib);
        // JSON.parse(obj);
        return libelle;
    }
    useEffect(() => {

        if (sessionStorage.getItem('profil') !== 'Admin') {
            setInfosAnnee(JSON.parse(sessionStorage.getItem('topBarInfos')));
            formatInfosAnnee(JSON.parse(sessionStorage.getItem('topBarInfos')));
        }
        console.log('-- infos année has changed --');

    }, []);


    return (
        <div className="layout-topbar">
            <Toast ref={toastBC} position="center" />
            <Toast ref={toast} />
            <Link to="/home/professionnelle" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-gain.png' : 'assets/layout/images/logo-gain.png'} alt="logo" onClick={refresh}  />
                <span>Pouls-scolaire</span>
            </Link>
            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <div className={infosAnnee?.anneeLibelle ? 'col-offset-1' : 'hidden'}>
                <Tag className={infosAnnee?.anneeLibelle ? 'px-5 py-2' : 'hidden'} severity={infosAnnee?.severity} value={libelle} ></Tag>
            </div>
            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>

                <li>
                    <div>
                        <span className="block text-500 font-medium mb-3">{sessionStorage.getItem('NomPrenom')}</span>

                    </div>
                    <div>
                        <span className="text-green-500 font-medium"> {sessionStorage.getItem('profil')} </span>
                    </div>
                    <div>
                        <span className="text-orange-500 font-medium"> {sessionStorage.getItem('EcoleLibelle')} </span>
                    </div>
                </li>


                <li>
                    <button className="p-link layout-topbar-button" onClick={showConfirm}>
                        <i className="pi pi-power-off" />
                        <span>Déconnexion</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}
