import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import Form from "./components/Form";
import PageAccueil from "./components/login/PageAccueil";
// import  Accueil from "./components/login/Accueil";

import { HashRouter } from 'react-router-dom'
import FileDemo from "./components/FileDemo";
window.$baseUri ="https://pouls-scolaire.com/api/"
//  window.$baseUri ="http://localhost:8889/api/"

    ReactDOM.render(
    <HashRouter>

 {<PageAccueil></PageAccueil>}


    </HashRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
